import React from 'react';
import trackData, { trackDataV2 } from '../../logic/analytics';
import forms from '../forms';

const TrackerContext = React.createContext();
const LOCALSTORAGE_USERID = "__mcmbarilla_user_id__";

function TrackerProvider(props) {
    async function trackDataFn(id, token, action, metadata, hasOrders) {
        function checkSession() {
            let lastSession = window.localStorage.getItem(LOCALSTORAGE_USERID);

            if (lastSession) {
                lastSession = JSON.parse(lastSession);
                console.log(Math.abs(lastSession.time - Date.now()))
                if (Math.abs(lastSession.time - Date.now()) <= 7200000) {
                    window.localStorage.setItem(LOCALSTORAGE_USERID, JSON.stringify({ token: lastSession.token, time: Date.now() }));
                    return true;
                }
            }

            window.localStorage.setItem(LOCALSTORAGE_USERID, JSON.stringify({ token: forms.generateRandomID(""), time: Date.now() }));
            return false;
        }

        await trackData(id, action, metadata);
        await trackDataV2(id, token, action, metadata, hasOrders);

        return checkSession();

        // if (action === "usersession" && checkSession() === true) {
        //     await trackData(id, action, metadata);
        //     await trackDataV2(id, token, action, metadata, hasOrders);
        //     return true;
        // } else if (action !== "usersession") {
        //     await trackData(id, action, metadata);
        //     await trackDataV2(id, token, action, metadata, hasOrders);
        // }
    }

    const value = {
        trackDataFn,
    };

    return <TrackerContext.Provider value={value} {...props} />
}

function useTracker() {
    let context = React.useContext(TrackerContext);
    if (!context) {
        throw new Error("useTracker must be used within a TrackerProvider");
    }
    return context;
}

export {
    TrackerProvider,
    useTracker,
}
