import React from 'react';
import { IonModal } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import "../../components/modals/SurveyModalStyle.css"

function SurveyModal(props) {
    const { modalOpen, setModalOpen } = props;

    const { t } = useTranslation();

    return (
        <IonModal cssClass="survey-trigger" isOpen={modalOpen} onDidDismiss={() => setModalOpen(false)}>
            <div className="overflow-auto bg-white text-black">
                <div className="px-8 pt-8 text-center">
                    <p>Sei stato soddisfatto ?</p>
                    <a className="underline text-[#4c5d47]" href="https://www.barilla.com/it-it/al-bronzo-survey-restaurant?rst=IT003&hl=it" target="_blank" rel="nooppener noreferrer">
                        Ci farebbe piacere che compilassi il nostro sondaggio
                    </a>
                </div>
                <div className="absolute bottom-0 pt-4 pb-4 text-white bg-[#8a7058] rounded-b-lg w-full">
                    <button className="w-full" onClick={() => setModalOpen(false)}>{t("deliverypopupmodalcontent.button")}</button>
                </div>
            </div>
        </IonModal>
    );
}

export default SurveyModal;
