import React, { useLayoutEffect, useState } from 'react';
import { useParams } from 'react-router';
import { IonContent, IonPage } from "@ionic/react";
import { useTracker } from '../utils/contexts/tracker-context';
import RestaurantLoader from '../components/containers/RestaurantLoader';
import Splash from '../components/UI/Splash';
import LanguageSelector from '../components/LanguageSelector';
import features from '../utils/features';
import theme from '../utils/theme';
import { useRestaurant } from '../utils/contexts/restaurant-context';
import SurveyModal from '../components/modals/SurveyModal';

function SplashPage(props) {
    const { trackDataFn } = useTracker();

    const [palette, setPalette] = useState("");
    const [modalOpen, setModalOpen] = useState(false);

    const { type, id } = useParams();
    const { restaurant } = useRestaurant();

    useLayoutEffect(() => {
        if (restaurant) {
            if (!process.env.REACT_APP_THEME) {
                theme.getTheme(restaurant.id, setPalette);
            } else {
                setPalette(process.env.REACT_APP_THEME);
            }
        }

        async function track() {
            if (restaurant) {
                const session = await trackDataFn(restaurant.id, restaurant.token, "usersession", { "type": type }, restaurant.has_orders);
                if (session === true) {
                    setModalOpen(true);
                }
            }
        }

        track();
    }, [restaurant]);

    return (
        <IonPage>
            <IonContent style={theme.getThemeIonComponents(palette).splashPage}>
                <section id="splash-page" className="font-sans">
                    <RestaurantLoader component={Splash} {...props} />
                </section>
                <SurveyModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
                {features.toggler().includeLanguageSelector() && <LanguageSelector onMenu={false} />}
            </IonContent>
        </IonPage>
    );
}

export default SplashPage;
